<template>
  <div class="new-price">
    <v-container fluid>
      <v-row style="background: linear-gradient(90deg, rgba(227,255,231,.6) 0%, rgba(217,231,255,.69) 100%);">
        <div class="title-card" style="position: relative">
          <v-col cols="12" sm="8" md="6">
            <div
              style="font-size: 2.5rem; font-weight: 600; line-height: 3.2rem; text-align: left; margin-bottom: 34px"
            >
              Зміна ціни обслуговування
            </div>
            <div style="text-align: left; font-size: 1.2rem">
              У зв’язку з підвищенням рівня інфляції, збільшенням терміну дебіторської заборгованості та підняттям ціни
              нашими партнерами, які забезпечують безперебійну роботу платформи на виділених серверах ми змушені підняти
              ціну на наші послуги. <span
              class="font-weight-bold">з 01.03.2023 року ціна обслуговування буде змінена.</span>
              Детальніша інформація щодо актуальних цін наведена нижче
            </div>
            <div style="text-align: left; font-size: 1.2rem; font-weight: 600; margin-top: 20px;">
              Дякуємо за розуміння
            </div>
          </v-col>
          <div style="position: absolute; right: 0; bottom: 0; top: 0" v-if="$vuetify.breakpoint.smAndUp">
            <img :src="`${$publicPath}img/images/info_3.png`"
                 style="height: 100%; max-width: 500px; opacity: .7; filter: hue-rotate(-100deg);"/>
          </div>
        </div>
      </v-row>
    </v-container>
    <v-container fluid>
      <v-row>
        <v-col cols="12">
          <h1 style="font-weight: bold; font-size: 3rem; margin-bottom: 0; margin-top: 30px">А які нові ціни?</h1>
        </v-col>
        <v-col cols="12" class="pt-0">
          <div style="max-width: 1200px; width: 100%; margin: 0 auto">
            <div style="font-weight: 400; font-size: 1.2rem; text-align: center; color: #5e5e5e">
              Ми дійсно змушені піти на цей крок, аби забезпечити пезперебійність та стабільність роботи нашої
              платформи. Нова ціна буде врахована у акті-виконаних робіт/наданих послуг за Березень 2022 року.
              Ще раз дякуємо Вам за розуміння
            </div>
          </div>
        </v-col>
        <div style="max-width: 900px; width: 100%; margin: 0 auto; display: flex; flex-wrap: wrap"
             class="my-12"
        >
          <v-col cols="12" sm="12" md="6">
            <v-card>
              <v-card-text class="grey lighten-3 grey--text text--darken-2 font-weight-bold pa-12 price"
                           style="font-size: 2rem; line-height: normal">
                до 75 особових рахунків
              </v-card-text>
              <v-card-text style="line-height: normal" class="pb-6">
                <div>
                  <v-chip outlined color="error">Стара ціна</v-chip>
                </div>
                <div style="font-size: 2rem">Максимальна ціна</div>
                <div style="font-size: 6rem; font-weight: bold">150</div>
                <div style="font-size: 2.6rem">грн./міс.</div>
              </v-card-text>
              <v-divider></v-divider>
              <v-card-text style="line-height: normal" class="py-6">
                <div>
                  <v-chip outlined color="success">Нова ціна</v-chip>
                </div>
                <div style="font-size: 2rem">Максимальна ціна</div>
                <div style="font-size: 6rem; font-weight: bold">200</div>
                <div style="font-size: 2.6rem">грн./міс.</div>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12" sm="12" md="6">
            <v-card>
              <v-card-text class="grey darken-2 white--text font-weight-bold pa-12 price"
                           style="font-size: 2rem; line-height: normal">
                від 76 до 400 особових рахунків
              </v-card-text>
              <v-card-text style="line-height: normal" class="pb-6">
                <div>
                  <v-chip outlined color="error">Стара ціна</v-chip>
                </div>
                <div style="font-size: 2rem">Максимальна ціна</div>
                <div style="font-size: 6rem; font-weight: bold">800</div>
                <div style="font-size: 2.6rem">грн./міс.</div>
              </v-card-text>
              <v-divider></v-divider>
              <v-card-text style="line-height: normal" class="py-6">
                <div>
                  <v-chip outlined color="success">Нова ціна</v-chip>
                </div>
                <div style="font-size: 2rem">Максимальна ціна</div>
                <div style="font-size: 6rem; font-weight: bold">900</div>
                <div style="font-size: 2.6rem">грн./міс.</div>
              </v-card-text>
            </v-card>
          </v-col>
        </div>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  name: 'NewPrice'
}
</script>

<style scoped>

</style>
